import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, CreateInvoiceChargeRequest, InvoiceCharge } from '@/api/types'

export default class InvoiceChargeService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<InvoiceCharge[]>> {
    return await this.call('/v1/dpp/invoice-charges', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<InvoiceCharge>> {
    return await this.call(`/v1/dpp/invoice-charges/${id}`, options)
  }

  async create(data: CreateInvoiceChargeRequest): Promise<ApiResponse<InvoiceCharge>> {
    return await this.call('/v1/dpp/invoice-charges', {
      method: 'post',
      body: data,
    })
  }

  async update(id: string | number, data: CreateInvoiceChargeRequest): Promise<ApiResponse<InvoiceCharge>> {
    return await this.call(`/v1/dpp/invoice-charges/${id}`, {
      method: 'put',
      body: data,
    })
  }

  async delete(id: string | number): Promise<void> {
    return await this.call(`/v1/dpp/invoice-charges/${id}`, {
      method: 'delete',
    })
  }
}
